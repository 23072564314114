import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2f9f74f9&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=2f9f74f9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f9f74f9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHSmallBannerGrid: require('/usr/src/app/src/components/v2/organisms/RPHSmallBannerGrid/index.vue').default,Heading: require('/usr/src/app/src/components/atoms/Heading.vue').default,RPHSearchBar: require('/usr/src/app/src/components/v2/organisms/RPHSearchBar/index.vue').default,RPHLargeBannerGrid: require('/usr/src/app/src/components/v2/organisms/RPHLargeBannerGrid/index.vue').default})
